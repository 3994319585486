import React from "react"
import { Link } from "gatsby"
import NetversysSignet from "../../assets/images/Netversys_Black_1C_Signet.svg"
import NetstorsysSignet from "../../assets/images/Netstorsys_Black_1C_Signet.svg"
import { StaticImage } from "gatsby-plugin-image"

const OurProducts = ({
                       withScreenshots = false,
                       title = "Software für Logistik mit Cloudtechnologie",
                       makeH1 = false,
                       smallIcons = false
                     }) => {
  return (
    <section className="solutions-area pb-70">
      <div className="container">
        <div className="section-title">
          {!withScreenshots && <span className="sub-title">
                        Unsere Produkte
                    </span>
          }
          {makeH1 && <h1>{title}</h1>}
          {!makeH1 && <h2>{title}</h2>}

          <p>Unsere Produkte sind seit der initialen Entwicklung server- und browserbasiert. Erleben Sie mit uns
            den technologischen Vorsprung.</p>
        </div>

        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <div className="single-solutions-box bg-fafafb">
              <img src={NetversysSignet} alt={"Netversys Signet"} width={smallIcons ? "40px" : "80px"} className={"m-2"} />
              <h3>
                <Link to="/versandsoftware/" style={{ textDecoration: "none" }}>
                  NETVERSYS
                </Link>
              </h3>
              {withScreenshots && <StaticImage
                src="../../assets/images/products/netversys/devices/nv_dashboard_devices_all.png"
                alt="Entgeltmanagement Screenshot"
                placeholder="blurred"
                width={600}
                className={"mb-4"}
              />}
              <p>
                Versandsoftware wie sie sein soll. Schnell, komfortabel und mit allen Carriern die Sie benötigen.
                Starten Sie mit NETVERSYS durch und seien Sie der Konkurrenz vorraus.
              </p>

              <Link to="/versandsoftware/" className="btn btn-primary"
                    style={{ width: "220px", textDecoration: "none" }}>
                zum Produkt >>>
              </Link>
            </div>
          </div>

          <div className="col-sm-6 col-xs-12">
            <div className="single-solutions-box bg-fafafb">
              <img src={NetstorsysSignet} alt={"Netversys Signet"} width={smallIcons ? "40px" : "80px"} className={"m-2"} />

              <h3>
                <Link to="/lagerverwaltung/" style={{ textDecoration: "none" }}>
                  NETSTORSYS
                </Link>
              </h3>
              {withScreenshots && <StaticImage
                src="../../assets/images/products/netstorsys/devices/ns_dashboard_devices_all.png"
                alt="Entgeltmanagement Screenshot"
                placeholder="blurred"
                width={600}
                className={"mb-4"}
              />}
              <p>
                Die Software für Lagerlogistik im Zeitalter der Cloud. Erleben Sie modernes Warehouse
                Management für den Bruchteil der Kosten anderer Systeme.
              </p>

              <Link to="/lagerverwaltung/" className="btn btn-primary"
                    style={{ width: "220px", textDecoration: "none" }}>
                zum Produkt >>>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurProducts
