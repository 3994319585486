import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/App/Banner"
import fourZeroFour from "../assets/images/404.svg"
import { Helmet } from "react-helmet"
import OurProducts from "../components/Index/OurProducts"

const Error = ({ location }) => {
  return (
    <Layout>
      <Helmet>
        <title>Die Seite wurde nicht gefunden</title>
        <meta name={"description"} content={"Die Seite wurde nicht gefunden"} />
      </Helmet>
      <Navbar />
      <Banner headerImage={fourZeroFour} mainText={`Die angeforderte Seite '${location.pathname}' existiert 
      nicht oder ist fehlerhaft. Falls Sie unsere Produkte suchen, weiter unten in der Seite haben wir Links 
      zu den NETVERSYS und NETSTORSYS.`}
              mainTitle={"Ooops. Wir haben die Seite nicht gefunden."} />
      <OurProducts />
      <Footer />
    </Layout>
  )
}

export default Error
